import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';
import { ExpenseType } from '../../../interface/expense-type';
import { PartGroup } from '../../../interface/part-group';
import { PartSubgroup } from '../../../interface/part-subgroup';
import { ExpenseService } from '../../../service/expense.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';

interface Exp {
  _id: string;
  description: string;
  group: string;
  subgroup: string;
}

@Component({
  selector: 'app-expense-edit',
  templateUrl: './expense-edit.component.html',
  styleUrl: './expense-edit.component.scss'
})
export class ExpenseEditComponent extends UnsubscribeAll implements OnDestroy {

  form = new FormBuilder().group({
    _id: '',
    description: ['', Validators.required],
    group: '',
    subgroup: '',
    dre: true // default is true
  });
  groups: PartGroup[] = [];
  subgroups$ = new BehaviorSubject<PartSubgroup[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {
      expense?: ExpenseType,
      groups: PartGroup[]
    },
    private dialogRef: MatDialogRef<ExpenseEditComponent>,
    private expenseService: ExpenseService,
    private snackbar: SnackbarService,
    private layout: LayoutService
  ) {
    super();

    if (data) {
      if (data.expense) {

        // start initial subgroup list of selected group
        if(data.expense.group){
          const group = data.groups.find(g => g._id === data.expense.group);
          if(group){
            this.subgroups$.next(group.subgroups);
          }
        }

        this.form.patchValue(data.expense);
      }

      if (data.groups) {
        this.groups = data.groups;
      }
    }

    // change subgroups list at change group
    this.subs.push(this.form.controls.group.valueChanges.subscribe(newId => {
      const selectedGroup = this.groups.find(g => g._id === newId);
      if(selectedGroup){
        this.subgroups$.next(selectedGroup?.subgroups || []);
        this.form.controls['subgroup'].enable();
      } else {
        this.subgroups$.next([]);
        this.form.controls['subgroup'].disable();
      }
    }))
  }

  override ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  async onSubmit() {
    let value = this.form.getRawValue();
    if(value.group == ''){
      delete value.group;
    }
    if(value.subgroup == ''){
      delete value.subgroup;
    }
    // console.log({expense: value})
    const isNew = !this.form.get('_id').value;
    try {
      this.layout.loader = true;
      if (isNew) {
        const id = await this.expenseService.create(value);
        value['_id'] = id;
        this.snackbar.show('EXPENSE.ADDED', true)
      } else {
        await this.expenseService.update(value);
        this.snackbar.show('EXPENSE.UPDATED', true)
      }
      this.dialogRef.close(value);
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar tipo de despesa\n\n", error)
      this.snackbar.show(isNew ? 'EXPENSE.NOT_ADDED' : 'EXPENSE.NOT_UPDATED', false)
    } finally {
      this.layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close()
  }

}
