<h1 mat-dialog-title>
  {{ form?.value?._id ? 'Atualizar' : 'Cadastrar' }} tipo de despesa
</h1>
<form [formGroup]="form"
      autocomplete="off">
  <mat-dialog-content>
    <mat-form-field class="col-12">
      <mat-label>Descrição</mat-label>
      <input matInput
             formControlName="description"
             required>
      <mat-error *ngIf="form.controls.description?.errors">
        {{ form.get('description').errors | error | translate : form.get('description').errors }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-12">
      <mat-label>Grupo</mat-label>
      <mat-select formControlName="group">
        @for (group of groups; track $index) {
        <mat-option [value]="group._id">
          {{ group.description }}
        </mat-option>
        }
        <mat-option [value]="null">&#x2051;&nbsp;Nenhum&nbsp;&#x2051;</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-12">
      <mat-label>Subgrupo</mat-label>
      <mat-select formControlName="subgroup">
        @for (subgroup of subgroups$ | async; track $index) {
        <mat-option [value]="subgroup._id">
          {{ subgroup.description }}
        </mat-option>
        }
        <mat-option [value]="null">&#x2051;&nbsp;Nenhum&nbsp;&#x2051;</mat-option>
      </mat-select>
    </mat-form-field>


    <div class="col-12">
      <mat-slide-toggle formControlName="dre"
                        color="primary">Aparece no DRE</mat-slide-toggle>
    </div>


  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button aria-label="cancel"
            mat-raised-button
            color="accent"
            type="button"
            (click)="close()">
      {{ 'ACTION.SHARED.CANCEL' | translate }}
    </button>
    <button aria-label="save"
            mat-raised-button
            color="primary"
            (singleClick)="onSubmit()"
            [disabled]="!form.valid">
      {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
    </button>
  </mat-dialog-actions>
</form>